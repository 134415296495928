<template>
    <div class="content">
        <div>
          <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
          <el-button type="primary" class="el-icon-download" @click="SendClick"> 下发</el-button>
        </div>

        <el-table :data='DataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="user_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="user_login_id" label="用户名"></el-table-column>
            <el-table-column align="center" prop="gp_name" label="所属组别"></el-table-column>
            <el-table-column align="center" prop="user_phone" label="联系方式"></el-table-column>
            <el-table-column align="center" prop="user_login_time" label="最近登录日期">
                <template slot-scope="slot">
                    {{FormateDate(slot.row.user_login_time)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                        <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                        <el-link type="primary" @click="Pwd(scope.row)">修改密码</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='450px' :append-to-body='true'>
            <el-form :model="userForm" :rules="rules" ref="userForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="user_name">
                    <el-input v-model="userForm.user_name"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="user_login_id">
                    <el-input v-model="userForm.user_login_id"></el-input>
                </el-form-item>
                <el-form-item label="所属组别" >
                    <el-select v-model="userForm.gp_id" style="width:100%;">
                      <el-option label="无" :value="0"></el-option>
                      <el-option v-for="item in GroupDataList" :key="item.gp_id" :label="item.gp_name" :value="item.gp_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系方式" >
                    <el-input v-model="userForm.user_phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="pwdDialogVisible" :title="title" :visible.sync="pwdDialogVisible" width='30%' :append-to-body='true'>
            <pwd ref='childRules' :pwdForm='pwdForm'/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="pwdDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ChangePwd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import Pwd from '../../components/pwd.vue'

export default {
  data: function () {
    return {
      DataList: [],
      GroupDataList: [],
      User:null,
      userForm: {
        user_id: 0,
        user_name: '',
        user_type: 0,
        user_login_id: '',
        gp_id: 0,
        user_phone:''
      },
      pwdForm: {
        user_id: 0,
        user_pwd: ''
      },
      dialogVisible: false,
      pwdDialogVisible: false,
      title: '',
      rules: {
        user_name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        user_login_id: [{ required: true, message: '请输入用户名', trigger: 'blur' }]
      },
      isFinish:true
    }
  },
  components: {
    Pwd
  },
  computed: {
    FormateDate () {
      return function (time) {
        if (time) { return util.FormateDate(time, 2) } else { return '' }
      }
    }
  },
  created () {
    this.User=util.JesToken()
    util.Get('group/getgroupdatalist').then(res => {
      if (res.rpStatus === 10000) {
        this.GroupDataList = res.list[0]
      }
    })
    this.GetUserDataList()
  },
  methods: {
    GetUserDataList () {
      util.Get('user/getuserdatalist?userType='+this.User.user_type+'&id='+this.User.user_sp_id).then(res => {
        if (res.rpStatus === 10000) {
          this.DataList = res.list
        }
      })
    },
    AddClick () {
      if(util.CheckUserAuth('5-2-1')){
        this.dialogVisible = true
        this.title = '新增'
        this.userForm.user_id = 0
        this.userForm.user_name = ''
        this.userForm.user_login_id = ''
        this.userForm.gp_id = 0
        this.userForm.user_phone=''
        if (this.GroupDataList.length > 0) { this.userForm.user_group_id = this.GroupDataList[0].gp_id }
      }
    },
    Edit (row) {
      if(util.CheckUserAuth('5-2-2')){
        this.dialogVisible = true
        this.title = '修改'
        this.userForm = JSON.parse(JSON.stringify(row))
      }
    },
    submit () {
      this.$refs.userForm.validate((valid) => {
        if (valid) {
          this.userForm.user_sp_id=this.User.user_sp_id
          this.userForm.user_type=this.User.user_type
          util.Post('user/edit', this.userForm).then(res => {
            if (res.rpStatus === 10000) {
              this.GetUserDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Del (row) {
      if(util.CheckUserAuth('5-2-3')){
        this.$confirm('确定删除用户“' + row.user_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('user/del?id=' + row.user_id).then(res => {
            if (res.rpStatus === 10000) { this.GetUserDataList() } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
          })
        }).catch(e => e)
      }
    },
    Pwd (row) {
      if (this.User.user_login_id !== row.user_login_id) {
        this.$message.error('禁止修改其他用户密码')
        return
      }
      this.pwdDialogVisible = true
      this.title = '修改密码'
      this.pwdForm.user_id = row.user_id
      this.pwdForm.user_pwd = ''
    },
    ChangePwd () {
      const flag = this.$refs.childRules.validateForm()
      if (flag) {
        util.Post('user/editpwd', this.pwdForm).then(res => {
          if (res.rpStatus === 10000) {
            this.$message({
              message: '修改密码成功',
              type: 'success'
            })
            this.pwdDialogVisible = false
          }
        })
      }
    },
    SendClick () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('user').then(res=>{
          this.isFinish=true
        })
      }
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
